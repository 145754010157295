@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.socialls {
  display: flex;
  .social-button {
    width: vw_d(28);
    height: vw_d(27.7);
    margin-right: vw_d(11);
    border: solid vw_d(1) #bbbbbb;
    border-radius: 50%;
    @include screen('mobile') {
      width: vw(43.6);
      height: vw(43.1);
      margin-right: vw(18);
      border: solid vw(1) #bbbbbb;
    }
    &:hover {
      i {
        svg {
          ellipse {
            fill: #f3c52a !important;
          }
          path {
            fill: #000 !important;
          }
        }
      }
    }
    i {
      display: block;
      width: 100% !important;
      height: 100% !important;
      transition: all ease 0.3s;
      svg {
        display: block;
        width: 100% !important;
        height: 100% !important;
        ellipse,
        path {
          transition: all ease 0.3s;
        }
      }
    }
  }
  .copy-button {
    width: vw_d(28);
    height: vw_d(27.7);
    position: relative;
    margin-right: vw_d(11);
    border: solid vw_d(1) #bbbbbb;
    border-radius: 50%;
    @include screen('mobile') {
      width: vw(43.6);
      height: vw(43.1);
      margin-right: vw(18);
      border: solid vw(1) #bbbbbb;
    }
    &:hover {
      i {
        svg {
          ellipse {
            fill: #f3c52a !important;
          }
          path {
            stroke: #000 !important;
          }
        }
      }
    }
    i {
      display: block;
      width: 100% !important;
      height: 100% !important;
      transition: all ease 0.3s;
      svg {
        display: block;
        width: 100% !important;
        height: 100% !important;
        ellipse,
        path {
          transition: all ease 0.3s;
        }
      }
    }
    .copy-text {
      position: absolute;
      left: 50%;
      top: vw_d(34);
      font-size: vw_d(12);
      line-height: vw_d(11);
      font-family: $light-font;
      transform: translate(-50%, 0);
      opacity: 0;
      @include screen('mobile') {
        top: vw(52);
        font-size: vw(14);
        line-height: vw(12);
        white-space: nowrap;
      }
      &.show {
        opacity: 1;
        animation: Shiver 0.5s ease-in-out forwards;
      }
    }
  }
}

@keyframes Shiver {
  0% {
    transform: translate(-50%, 0) rotate(0deg);
  }

  25% {
    transform: translate(-50%, 0) rotate(10deg);
  }

  50% {
    transform: translate(-50%, 0) rotate(-5deg);
  }

  75% {
    transform: translate(-50%, 0) rotate(5deg);
  }

  100% {
    transform: translate(-50%, 0) rotate(0deg);
  }
}
