@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.header {
  width: 100%;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin-bottom: vw_d(22);
  position: relative;

  @include screen('mobile') {
    margin-bottom: vw(22);
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .header-logo-desk {
    width: 100%;

    @include screen('mobile') {
      display: none;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .header-logo-mob {
    display: none;

    @include screen('mobile') {
      display: block;
      width: 100%;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .socialls-wrapper {
    position: absolute;
    bottom: vw_d(15);
    left: 50%;
    transform: translate(-50%, 0%);
    display: flex;
    @include screen('mobile') {
      width: 100%;
      justify-content: center;
      bottom: vw(15);
    }
  }
}
